

<!-- 附件删除bug  修复
      搜索框 修复
      loading  已加
-->


<template>
  <div class="teamPage">
    <div class="child-top" style="padding-top: 0">
      <div class="input-from">
        <el-date-picker
          v-model="date"
          type="week"
          align="right"
          unlink-panels
          :format="dateList[0] + ' 至 ' + dateList[6]"
          :picker-options="pickerOptions"
          @change="deparmentChange"
          class="picker_date"
        >
        </el-date-picker>
        <!-- <el-date-picker
          v-model="date"
          type="week"
          placeholder="选择周">
        </el-date-picker> -->
        <el-cascader
          style="float: left"
          placeholder="请选择运动队"
          clearable
          @change="deparmentChange"
          v-model="deparmentId"
          :options="departmentOptions"
          :show-all-levels="false"
          collapse-tags
          :props="{
            children: 'children',
            label: 'name',
            value: 'uuid',
            emitPath: false,
          }"
        ></el-cascader>
        <!-- <el-select
          clearable
          placeholder="请选择离队状态"
          v-model="isOut"
          @change="getList"
        >
          <el-option label="在队" value="0"></el-option>
          <el-option label="离队" value="1"></el-option>
        </el-select>
        <div>
          <el-input
            class="left-child-input left-child-input-content"
            @keyup.enter.native="getList"
            @clear="getList"
            clearable
            placeholder="请输入运动员姓名"
            style="width: 200px; float: left"
            suffix-icon="iconfont iconsousuo"
            v-model="inputValue"
          >
          </el-input>
          <el-button class="searchBtn" round @click="getList">搜索</el-button> -->
        <!-- </div> -->
      </div>
      <div class="operation-button">
        <el-button class="new_btn" operation-button round @click="weekPlanAdd" :disabled="$store.getters.permissionsStr('新增')">
          <span>新增周计划</span>
        </el-button>
        <!-- <el-button class="del_btn" style="background: #e7b308" round>
          <span>打印</span>
        </el-button> -->
      </div>
    </div>

    <div class="teamPage_content" v-loading="contentLoading">
      <div class="teamPage_content_sum">
        <span class="sum_name">周计划概述</span>
        <el-input
          style="height: 100%;"
          class="sum_input"
          type="text"
          maxlength="100"
          @blur="trainBlur"
          v-model="trainInput"
          placeholder="请填写周训练任务与要求"
          :disabled="$store.getters.permissionsStr('编辑')"
        ></el-input>
        <div class="_file" @click="uploadBtn">
          <i class="el-icon-paperclip"></i> 附件({{ fileNum }})
        </div>
      </div>
      <div class="teamPage_content_people">
        <span class="sum_people">运动员</br>( {{ getTrainTeam.attend_staffs_count }}/{{ getTrainTeam.staffs_count }} )</span>
        <ul :class="rollType ? 'roll_people roll_active' : 'roll_people'">
          <li v-for="(item, index) in getTrainTeam.attend_staffs" :key="index" @click="personageClick(item.uuid)">{{item.name}}</li>
          <i v-show="getTrainTeam.attend_staffs && getTrainTeam.attend_staffs.length > 26" :class="rollType ? 'el-icon-arrow-up downBtn' : 'el-icon-arrow-down downBtn'" @click="() => rollType = !rollType"></i>
        </ul>
        <div class="other_people">
          <span>其他队员({{ getTrainTeam.unattend_staffs_count }}/{{ getTrainTeam.staffs_count }})</span>
          <i class="el-icon-caret-top trigon-icon"></i>
          <ul class="detail_people">
            <li v-for="(item, index) in getTrainTeam.unattend_staffs" :key="index" @click="personageClick(item.uuid)">{{item.name}}</li>
          </ul>
        </div>
      </div>
      <!-- <div class="date_head">
        <span
          :class="theday == item ? 'date_active' : ''"
          v-for="(item, index) in dateList"
          :key="index"
          >{{ dateName[index] }} {{ item.slice(5, 7) }}/{{
            item.slice(8, 10)
          }}</span
        >
      </div>
      <div
        class="member_content"
        v-for="(item, index) in interDataList"
        :key="index"
      >
        <div
          class="member_content_top"
          @click="personageClick(item.staff_uuid)"
        >
          <img
            :src="item.head_img ? 'http://' + item.head_img : circleUrl"
            alt=""
          />
          <span>姓名: {{ item.staff_name }}</span>
          <span>性别：{{ item.sex == 1 ? "男" : "女" }}</span>
          <span>身高：{{ item.height }}cm</span>
          <span>体重：{{ item.weight }}kg</span>
          <span>项目：{{ item.sport_name }}</span>
          <span>运动队：{{ item.department_name }}</span>
        </div>
        <ul class="member_content_ul">
          <li v-for="(itm, idx) in item.details" :key="idx">
            <div class="li_top_con" @click="editPlan(item, itm, idx)">
              <p v-for="(im, ix) in itm.detail" :key="ix">
                <i :class="getColorClass(ix)"></i>
                <strong>{{ im.start_time }}-{{ im.end_time }}</strong>
                <span>{{ im.template_name }}</span>
              </p>
            </div>
            <div class="li_bot_con">
              <p>
                <span>计划时长：</span
                >{{ itm.plan_timelen ? itm.plan_timelen + "min" : "" }}
              </p>
              <p>
                <span>实际时长：</span
                ><el-input
                  @blur="
                    realValueChange(
                      index,
                      idx,
                      item.staff_uuid,
                      itm.real_timelen,
                      itm.rpe
                    )
                  "
                  v-model="itm.real_timelen"
                ></el-input
                >min
              </p>
              <p>
                <span>训练后RPE：</span
                ><el-input
                  @blur="
                    realValueChange(
                      index,
                      idx,
                      item.staff_uuid,
                      itm.real_timelen,
                      itm.rpe
                    )
                  "
                  v-model="itm.rpe"
                ></el-input>
              </p>
            </div>
          </li>
        </ul>
      </div> -->
      <div class="date_head">
        <span  :class="theday == item ? 'date_active' : ''" v-for="(item, index) in dateList" :key="index">{{dateName[index]}} {{item.slice(5,7)}}/{{item.slice(8,10)}}</span>
      </div>
      <div class="member_content">
        <ul class="member_content_ul">
          <li v-for="(itm, idx) in interDataList.details" :key="idx">
            <div class="li_top_con" @click="editPlan(itm, idx)">
              <el-tooltip class="item" effect="dark" :disabled="itm.target && itm.target.length < 18" :content="itm.target" placement="top">
                <p v-show="itm.target">目标：{{itm.target}}</p>
              </el-tooltip>
              <p v-for="(im, ix) in itm.detail" :key="ix">
                <i :class="getColorClass(ix)"></i>
                <strong>{{im.start_time}}-{{im.end_time}}</strong>
                <span>{{im.template_name}}</span>
              </p>
            </div>
            <div class="li_bot_con">
              <p><span>计划时长：</span>{{itm.plan_timelen ? itm.plan_timelen + 'min' : ''}}</p>
              <p><span>实际时长：</span><el-input @blur="realValueChange(idx, interDataList.organize_uuid, itm.real_timelen, itm.plan_rpe)" v-model="itm.real_timelen" :disabled="$store.getters.permissionsStr('编辑')"></el-input>min</p>
              <!-- <el-input @blur="realValueChange(idx, interDataList.organize_uuid, itm.real_timelen, itm.plan_rpe)" v-model="itm.plan_rpe" :disabled="$store.getters.permissionsStr('编辑')"></el-input> -->
              <p>
                <span>计划RPE：</span>
                <el-select placeholder="" style="width: 40%;" @change="realValueChange(idx, interDataList.organize_uuid, itm.real_timelen, itm.plan_rpe)" v-model="itm.plan_rpe" :disabled="$store.getters.permissionsStr('编辑')">
                  <el-option v-for="item in 10" :key="item" :label="item" :value="String(item)"></el-option>
                </el-select>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- 新增周训练计划 -->
    <el-dialog
      :title="referToButton ? '新增周训练计划' : '编辑周训练计划'"
      :visible.sync="weekPlanDialog"
      v-loading="contentLoading"
      :close-on-click-modal="false"
      :show-close="false"
      width="73%"
      center
    >
      <div class="weekDialogContent">
        <div class="dialogContent_top">
          <div class="top_left">
            <span><strong>*</strong>选择运动队</span>
            <el-cascader
              placeholder="请选择运动队"
              clearable
              v-model="addDeparmentId"
              :options="departmentOptions"
              :show-all-levels="false"
              collapse-tags
              @change="getStaffList"
              :props="{
                children: 'children',
                label: 'name',
                value: 'uuid',
                emitPath: false,
              }"
            ></el-cascader>
          </div>
          <div class="top_right">
            <span><strong>*</strong>选择队员</span>
            <el-select
              placeholder="请选择队员"
              v-model="checkMember"
              multiple
              @change="memberChange"
            >
              <el-option
                v-for="(item, index) in staffByList"
                :key="index"
                :label="item.name"
                :value="item.uuid"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="dialogContent_date">
          <el-date-picker
            v-model="addDate"
            type="week"
            align="right"
            unlink-panels
            :format="addDateList[0] + ' 至 ' + addDateList[6]"
            :picker-options="pickerOptions"
            @change="addWeekDays"
            :clearable="false"
            class="picker_date"
          >
          </el-date-picker>
          <div class="date-right">
            <span
              @click="addWeekIndex(index)"
              v-for="(item, index) in dateName"
              :key="index"
              :class="span_index == index ? 'span_active' : ''"
              >{{ item }}</span
            >
            <el-date-picker
              type="week"
              align="right"
              ref="referToIn"
              v-model="referToDate"
              prefix-icon="1"
              value-format="yyyy-MM-dd"
              @change="dateChange"
              :picker-options="pickerOptions"
              :clearable="false"
              :unlink-panels="true">
            </el-date-picker>
            <span class="refer_to" @click="referToClick" v-if="referToButton">参照</span>
          </div>
        </div>
        <div class="dialogContent_title">
          <span>训练目标</span>
          <el-input
            maxlength="100"
            placeholder="请输入今日训练目标"
            v-model="weekAddData[span_index].target"
          ></el-input>
        </div>
        <div class="dialogContent_tableTitle">
          <span>序号</span><span>训练方案</span><span>操作</span>
        </div>
        <div class="dialogContent_table">
          <div
            class="dialogContent_tableContent"
            v-for="(item, index) in weekAddData[span_index].templates"
            :key="index"
          >
            <div class="_tableContent_index">
              {{ index + 1 }}
            </div>
            <div class="_tableContent_right">
              <div class="_table_right_top">
                <el-time-picker
                  is-range
                  v-model="item.times"
                  format="HH:mm"
                  value-format="HH:mm"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                  @change="timesChange(index, item.times, true)"
                >
                </el-time-picker>
                计：<el-input v-model="item.timelen" disabled></el-input> min

                <el-button
                  @click="addActions(index, true)"
                  class="new_btn"
                  round
                  ><i class="el-icon-plus add_icon"></i>增加动作</el-button
                >
              </div>
              <div class="_table_right_bot">
                <div class="_right_bot_lf">
                  <el-select
                    placeholder="请选择训练计划模板"
                    v-model="item.template_uuid"
                    @change="templateChange(index, item.template_uuid, true)"
                  >
                    <el-option
                      v-for="(va, vx) in templateAll"
                      :key="vx"
                      :label="va.name"
                      :value="va.uuid"
                    ></el-option>
                    <el-option label="其它" value="0"></el-option>
                  </el-select>
                  <el-input
                    v-model="item.template_name"
                    v-if="item.template_uuid === '0'"
                    type="textarea"
                    :rows="2"
                    placeholder="请输入训练计划模板名称"
                  ></el-input>
                </div>
                <ul class="_right_bot_rg">
                  <li v-for="(itm, idx) in item.actions" :key="idx">
                    ({{ idx + 1 }})
                    <el-select
                      placeholder="请选择训练动作"
                      v-model="itm.action_uuid"
                      filterable
                    >
                      <el-option
                        v-for="(va, vx) in actionsList"
                        :key="vx"
                        :label="va.name"
                        :value="va.uuid"
                      ></el-option>
                    </el-select>
                    <span class="li_name">组数</span
                    ><el-input
                      v-model="itm.gnum"
                      @input="onInputValue(index, idx, 'gnum', true)"
                      ><span slot="suffix" class="el-input__icon"
                        >组</span
                      ></el-input
                    >
                    <span class="li_name">次数</span
                    ><el-input
                      v-model="itm.tnum"
                      @input="onInputValue(index, idx, 'tnum', true)"
                      ><span slot="suffix" class="el-input__icon"
                        >次</span
                      ></el-input
                    >
                    <span class="li_name">时长</span
                    ><el-input
                      v-model="itm.timelen"
                      @input="onInputValue(index, idx, 'timelen', true)"
                      ><span slot="suffix" class="el-input__icon"
                        >s</span
                      ></el-input
                    >
                    <span class="li_name">组间间歇</span
                    ><el-input
                      v-model="itm.ggap"
                      @input="onInputValue(index, idx, 'ggap', true)"
                    ></el-input>
                    <span class="li_name">负荷</span
                    ><el-input v-model="itm.loads" maxlength="30"></el-input>
                    <i
                      class="el-icon-error del_icon"
                      @click="delActions(index, idx, true)"
                    ></i>
                  </li>
                </ul>
              </div>
            </div>
            <div class="_tableContent_handle">
              <span @click="handle_del(index, true)">删除</span>
            </div>
          </div>
        </div>
        <el-button @click="addTemplate(true)" class="new_btn_part" round
          ><i class="el-icon-plus add_icon"></i>增加训练方案</el-button
        >
      </div>
      <div class="form-bottom-button">
        <el-button
          @click="weekPlanSave"
          type="primary"
          v-no-more-click
          class="save_btn"
          round
          >保存</el-button
        >
        <el-button @click="weekPlanCancel" class="cancel_btn" round
          >取消</el-button
        >
      </div>
    </el-dialog>

    <!-- 编辑个人训练计划 -->
    <el-dialog
      :visible.sync="editkPlanDialog"
      :close-on-click-modal="false"
      v-loading="contentLoading"
      :show-close="false"
      class="editDialog"
      width="73%"
      center
    >
      <div class="member_content_edit">
        <!-- <el-avatar :src="memberInfo.head_img ? 'http://' + memberInfo.head_img : circleUrl"></el-avatar> -->
        <img
          :src="
            memberInfo.head_img ? 'http://' + memberInfo.head_img : circleUrl
          "
          alt=""
        />
        <span>姓名: {{ memberInfo.staff_name }}</span>
        <span>性别：{{ memberInfo.sex == 1 ? "男" : "女" }}</span>
        <span>身高：{{ memberInfo.height }}cm</span>
        <span>体重：{{ memberInfo.weight }}kg</span>
        <span>项目：{{ memberInfo.sport_name }}</span>
        <span>运动队：{{ memberInfo.department_name }}</span>
        <p>{{ memberDate }} {{ memberWeek }}</p>
      </div>
      <div class="weekDialogContent">
        <div class="dialogContent_title">
          <span>训练目标</span>
          <el-input
            maxlength="100"
            placeholder="请输入今日训练目标"
            v-model="weekEditData.target"
          ></el-input>
        </div>
        <div class="edit_content_tab">
          <span :class="tab_index ? 'tab_active' : ''" @click="tabClick(true)"
            >训练计划</span
          >
          <span :class="!tab_index ? 'tab_active' : ''" @click="tabClick(false)"
            >营养建议</span
          >
        </div>
        <!-- <div class="dialogContent_top">
          <div class="top_left">
            <span><strong>*</strong>选择运动队</span>
            <el-cascader
              placeholder="请选择运动队"
              clearable
              v-model="addDeparmentId"
              :options="departmentOptions"
              :show-all-levels="false"
              collapse-tags
              @change="getStaffList"
              :props="{children: 'children', label: 'name', value: 'uuid', emitPath: false}"></el-cascader>
          </div>
          <div class="top_right">
            <span><strong>*</strong>选择队员</span>
            <el-select v-model="checkMember" multiple @change="memberChange">
              <el-option v-for="(item, index) in staffByList" :key="index" :label="item.name" :value="item.uuid"></el-option>
            </el-select>
          </div>
        </div>
        <div class="dialogContent_date">
          <el-date-picker
            v-model="addDate"
            type="week"
            align="right"
            unlink-panels
            :format="addDateList[0] + ' 至 ' + addDateList[6]"
            :picker-options="pickerOptions"
            @change="addWeekDays"
            :clearable="false"
            class="picker_date">
          </el-date-picker>
          <div class="date-right">
            <span @click="addWeekIndex(index)" v-for="(item, index) in dateName" :key="index" :class="span_index == index ? 'span_active' : ''">{{item}}</span>
          </div>
        </div> -->
        <div class="dialogContent_train" v-if="tab_index">
          <div class="dialogContent_tableMin">
            <span>计划总时长：</span>{{ weekEditData.plan_timelen }}min
            <span>实际训练时长：</span>
            <el-input v-model="weekEditData.real_timelen"></el-input>min
            <span>训练后RPE：</span>
            <el-input v-model="weekEditData.rpe"></el-input>
          </div>
          <div class="dialogContent_tableTitle">
            <span>序号</span><span>训练方案</span>
          </div>
          <div class="dialogContent_edit_table">
            <div
              class="dialogContent_tableContent"
              v-for="(item, index) in weekEditData.templates"
              :key="index"
            >
              <div class="_tableContent_index">
                {{ index + 1 }}
              </div>
              <div class="_tableContent_right">
                <div class="_table_right_top">
                  <el-time-picker
                    is-range
                    v-model="item.times"
                    format="HH:mm"
                    value-format="HH:mm"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    @change="timesChange(index, item.times, false)"
                  >
                  </el-time-picker>
                  计：<el-input v-model="item.timelen" disabled></el-input> min

                  <el-button
                    @click="addActions(index, false)"
                    class="new_btn"
                    round
                    ><i class="el-icon-plus add_icon"></i>增加动作</el-button
                  >
                </div>
                <div class="_table_right_bot">
                  <div class="_right_bot_lf">
                    <el-select
                      placeholder="请选择训练计划模板"
                      v-model="item.template_uuid"
                      @change="templateChange(index, item.template_uuid, false)"
                    >
                      <el-option
                        v-for="(va, vx) in templateAll"
                        :key="vx"
                        :label="va.name"
                        :value="va.uuid"
                      ></el-option>
                      <el-option label="其它" value="0"></el-option>
                    </el-select>
                    <el-input
                      v-model="item.template_name"
                      v-if="item.template_uuid === '0'"
                      type="textarea"
                      :rows="2"
                      placeholder="请输入训练计划模板名称"
                    ></el-input>
                  </div>
                  <ul class="_right_bot_rg">
                    <li v-for="(itm, idx) in item.actions" :key="idx">
                      ({{ idx + 1 }})
                      <el-select
                        placeholder="请选择训练动作"
                        v-model="itm.action_uuid"
                        filterable
                      >
                        <el-option
                          v-for="(va, vx) in actionsList"
                          :key="vx"
                          :label="va.name"
                          :value="va.uuid"
                        ></el-option>
                      </el-select>
                      <span class="li_name">组数</span
                      ><el-input
                        v-model="itm.gnum"
                        @input="onInputValue(index, idx, 'gnum', false)"
                        ><span slot="suffix" class="el-input__icon"
                          >组</span
                        ></el-input
                      >
                      <span class="li_name">次数</span
                      ><el-input
                        v-model="itm.tnum"
                        @input="onInputValue(index, idx, 'tnum', false)"
                        ><span slot="suffix" class="el-input__icon"
                          >次</span
                        ></el-input
                      >
                      <span class="li_name">时长</span
                      ><el-input
                        v-model="itm.timelen"
                        @input="onInputValue(index, idx, 'timelen', false)"
                        ><span slot="suffix" class="el-input__icon"
                          >s</span
                        ></el-input
                      >
                      <span class="li_name">组间间歇</span
                      ><el-input
                        v-model="itm.ggap"
                        @input="onInputValue(index, idx, 'ggap', false)"
                      ></el-input>
                      <span class="li_name">负荷</span
                      ><el-input v-model="itm.loads" maxlength="30"></el-input>
                      <i
                        class="el-icon-error del_icon"
                        @click="delActions(index, idx, false)"
                      ></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <el-button @click="addTemplate(false)" class="new_btn_part" round
            ><i class="el-icon-plus add_icon"></i>增加训练方案</el-button
          >
        </div>
        <div class="dialogContent_suggest" v-else>
          <h2>{{ NutritionalData.supply_name }}</h2>
          <div class="suggest_content">
            <div class="suggest_content_left">营养品补充建议</div>
            <div class="suggest_content_right">
              <div class="suggest_content_right_top">
                <span>使用时间</span>
                <span>名称</span>
                <span>使用数量</span>
                <span>使用方法</span>
                <span>使用目的</span>
              </div>
              <ul class="suggest_content_right_content">
                <li
                  v-for="(item, index) in NutritionalData.nutrition"
                  :key="index"
                >
                  <div class="right_content_li_name">
                    {{
                      index == 1
                        ? "晨起、睡前"
                        : index == 2
                        ? "训练前30分钟"
                        : index == 3
                        ? "训练前、中"
                        : index == 4
                        ? "训练后45分内"
                        : index == 5
                        ? "睡前"
                        : ""
                    }}
                  </div>
                  <div class="right_content_li_content">
                    <div
                      class="right_content_li_content_bot"
                      v-for="(itm, idx) in item"
                      :key="idx"
                    >
                      <span>
                        <i>{{ itm.nutrition_name }}</i>
                      </span>
                      <span>
                        <i>{{ itm.use_number }}</i>
                      </span>
                      <span>
                        <i>{{ itm.use_method }}</i>
                      </span>
                      <span>
                        <i v-for="(im, ix) in itm.use_goal" :key="ix">{{
                          im
                        }}</i>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="suggest_botContent">
            <div class="suggest_botContent_left">膳食营养方案</div>
            <div class="suggest_botContent_right">
              <p v-for="(item, index) in NutritionalData.diet" :key="index">
                {{ item }}
              </p>
            </div>
          </div>
          <div class="suggest_botContent" v-if="NutritionalData.other">
            <div class="suggest_botContent_left">其他方案</div>
            <div class="suggest_botContent_right">
              <!-- <p v-for="(item, index) in NutritionalData.diet" :key="index">
                {{item}}
              </p> -->
              {{ NutritionalData.other }}
              <!-- 睡前有氧慢跑、聆听舒缓音乐、泡温水澡也有利睡前有氧慢跑、聆听舒缓音乐、泡温水澡也有利于睡眠调整。睡眠问题多和训练节奏紧凑、赛前焦虑有关，需要教练多增加些心理疏导于睡眠调整。睡眠问题多和训练节奏紧凑、赛前焦虑有关，需要教练多增加些心理疏导 -->
            </div>
          </div>
        </div>
      </div>
      <div class="form-bottom-button">
        <el-button
          @click="editPlanSave"
          type="primary"
          v-no-more-click
          class="save_btn"
          round
          >保存</el-button
        >
        <el-button @click="editPlanCancel" class="cancel_btn" round
          >取消</el-button
        >
      </div>
    </el-dialog>

    <!-- 附件弹框 -->
    <el-dialog
      :title="`附件列表 (${fileNum})`"
      :visible.sync="fileDialogVisible"
      :close-on-click-modal="false"
      v-loading="contentLoading"
      width="50%"
      :show-close="false"
      center
      class="newDialog"
    >
      <div class="form_bot">
        <div class="inter_opBtn" v-loading="fileLoading">
          <el-upload
            class="upload-demo"
            :action="`${$store.state.img_url}/p/annex/add`"
            :data="{ master_uuid: fileListUuid, last_path: '3' }"
            :on-success="success"
            :before-upload="beforeUpload"
            :show-file-list="false"
            :file-list="newFileList"
          >
            <el-button type="primary" round>上传</el-button>
          </el-upload>
          <el-button type="primary" @click="upload_down" round>下载</el-button>
          <el-button type="primary" round @click="option_del">删除</el-button>
        </div>
        <el-checkbox-group v-model="checkList">
          <ul class="upload_ul">
            <li v-for="(item, index) in files" :key="index">
              <div
                :class="
                  checkList.indexOf(item.name) != -1
                    ? 'img_option img_active'
                    : 'img_option'
                "
                @click="fileTofile(item)"
              >
                <img :src="item.url" alt="" />
                <div
                  class="img_dialog"
                  v-if="checkList.indexOf(item.name) != -1"
                ></div>
              </div>
              <el-checkbox :label="item.name">{{ index + 1 }}</el-checkbox>
              <p>{{ item.name }}</p>
            </li>
          </ul>
        </el-checkbox-group>
      </div>

      <el-dialog :visible.sync="imgDialog" append-to-body class="append_dialog">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <span slot="footer" class="form-bottom-button">
        <!-- <el-button @click="fileSave" class="save_btn" round>保 存</el-button> -->
        <el-button @click="fileClose" class="cancel_btn" round>关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import wordUrl from "./../../../../static/word.jpg";
import xlsxUrl from "./../../../../static/xlsx.jpg";
import pptsUrl from "./../../../../static/ppts.jpg";
import pdfUrl from "./../../../../static/pdfs.jpg";
import morenUrl from "./../../../../static/file.png";
export default {
  data() {
    return {
      wordUrl: wordUrl,
      xlsxUrl: xlsxUrl,
      pptsUrl: pptsUrl,
      pdfUrl: pdfUrl,
      morenUrl: morenUrl,
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      date: "",
      addDate: "",
      isOut: "",
      deparmentId: "",
      addDeparmentId: "",
      inputValue: "",
      getTrainTeam: {},
      dateList: [],
      addDateList: [],
      theday: false,
      rollType: false,
      weekPlanDialog: false,
      editkPlanDialog: false,
      contentLoading: false,
      referToButton: true,
      memberInfo: {},
      memberItem: [],
      memberDate: [],
      memberWeek: "",
      tab_index: true,
      //周计划概述
      trainInput: "",

      // 选择队员
      checkMember: [],

      span_index: 0,
      dateName: [
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
        "星期日",
      ],
      referToDate: '',
      departmentOptions: [],
      staffByList: [],
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      interDataList: [],
      actionsList: [],
      weekAddData: [],
      templateAll: [],
      weekEditData: {
        train_date: "",
        target: "",
        plan_timelen: "",
        real_timelen: "",
        rpe: "",
        templates: [],
      },
      NutritionalData: {},

      fileListUuid: "",
      files: [],
      checkList: [],
      newFileList: [],
      fileNum: 0,
      fileDialogVisible: false,
      fileLoading: false,
      imgDialog: false, // 附件图片查看
      dialogImageUrl: "", // 附件查看图片地址
    };
  },
  computed:{
    customPickerOptions () {
      let that = this
      return {
        cellClassName (date) {
          if (that.customDateArr.includes(that.getFullDate(date))) {
            return 'custom_date_class'
          }
        }
      }
    }
  },
  created() {
    this.date = new Date();
    this.addDate = new Date();
    this.dateList = this.getWeekDays(new Date());
    this.theday = this.getFullDate(new Date());
    this.addDateList = this.getWeekDays(new Date());
    for (let i = 0; i < 7; i++) {
      this.weekAddData.push({
        train_date: "",
        target: "",
        plan_timelen: "",
        real_timelen: "",
        rpe: "",
        templates: [],
      });
    }
  },
  mounted() {
    // this.$nextTick(() => {});
    this.getDeparment()
      .then(() => {
        this.getTrain();
        this.getList();
        this.getParticipant()
      })
      .catch(() => {});
  },
  methods: {
    // 获取参赛人员
    getParticipant() {
      this.$axios
        .post(
          "/p/train/getTrainTeam",
          this.$qs({
            department_uuid: this.deparmentId,
            start_date: this.dateList[0],
            end_date: this.dateList[6]
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.getTrainTeam = res.data.data
          }
        })
    },
    // 参照 
     referToClick() {
      this.$refs.referToIn.focus()
     },
    // 编辑训练计划
    editPlan(u_item, u_key) {
      if(this.$store.getters.permissionsStr('编辑')) {
        return this.$message({
          message: '暂无权限',
          type: 'warning'
        })
      }
      /*  
        @u_info 个人信息
        @u_item 模板列表---单条信息
        @u_key  日期 
      */
      this.contentLoading = true;
      this.referToButton = false
      this.weekPlanDialog = true;
      // this.editkPlanDialog = true;
      // this.memberInfo = u_info; // 个人信息
      // let date = u_key.split("-");
      // this.memberDate = date[0] + "年" + date[1] + "月" + date[2] + "日"; // 日期
      // let index = this.dateList.indexOf(u_key);
      // this.memberWeek = this.dateName[index]; // 星期几

      this.span_index = this.dateList.indexOf(u_key)

      this.addDeparmentId = this.deparmentId
      this.getStaffList()
      this.getTrainTeam.attend_staffs.forEach(item => {
        this.checkMember.push(item.uuid)
      })
      // this.checkMember = this.getTrainTeam.attend_staffs

      this.$axios.post("/p/action/all").then((res) => {
        if (res.data.code == 0) {
          this.actionsList = res.data.data;
          this.$forceUpdate();
        }
      });
      // this.$axios.post("/p/action/all", this.$qs({
      //   uuid: u_item.staff_train_uuid,
      //   type: 'train'
      // })).then(res => {
      //   if(res.data.code == 0) {
      //     this.actionsList = res.data.data
      //     this.$forceUpdate()
      //   }
      // })

      this.$axios
        .post(
          "/p/template/all",
          this.$qs({
            uuid: u_item.staff_train_uuid,
            type: "train",
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.templateAll = res.data.data;
          }
        });

      this.$axios
        .post(
          "/p/train/readWeek",
          this.$qs({
            type: 1,
            organize_uuid: this.deparmentId,
            start_date: this.dateList[0],
            end_date: this.dateList[6]
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            res.data.data.train.forEach(item => {
              if(item.templates) {
                item.templates.forEach(itm => {
                  this.$set(itm, 'times', [itm.start_time, itm.end_time])
                  itm.actions.forEach(im => {
                    this.actionsList = this.$dataBack(this.actionsList, {name: im.action_name, uuid: im.action_uuid}, 'uuid', 'name')
                  })
                })
              } else {
                item.templates = []
              }
            })
            this.weekAddData = res.data.data.train
            // this.weekEditData = res.data.data.train;
            // if (
            //   !this.weekEditData.plan_timelen &&
            //   this.weekEditData.plan_timelen !== 0
            // ) {
            //   this.$set(this.weekEditData, "weekEditData", "");
            // }
            // if (
            //   !this.weekEditData.real_timelen &&
            //   this.weekEditData.real_timelen !== 0
            // ) {
            //   this.$set(this.weekEditData, "real_timelen", "");
            // }
            // if (!this.weekEditData.rpe && this.weekEditData.rpe !== 0) {
            //   this.$set(this.weekEditData, "rpe", "");
            // }
            // if (!this.weekEditData.target && this.weekEditData.target !== 0) {
            //   this.$set(this.weekEditData, "target", "");
            // }
            // if (
            //   !this.weekEditData.train_date &&
            //   this.weekEditData.train_date !== 0
            // ) {
            //   this.$set(this.weekEditData, "train_date", u_key);
            // }
            // this.weekEditData.templates.forEach((itm) => {
            //   this.$set(itm, "times", [itm.start_time, itm.end_time]);
            //   itm.actions.forEach((im) => {
            //     this.actionsList = this.$dataBack(
            //       this.actionsList,
            //       { name: im.action_name, uuid: im.action_uuid },
            //       "uuid",
            //       "name"
            //     );
            //   });
            // });
          }
          this.contentLoading = false;
        });

      // this.$axios
      //   .post(
      //     "/p/nutrition_supply/getDps",
      //     this.$qs({
      //       staff_uuid: u_info.staff_uuid,
      //       date: u_key,
      //     })
      //   )
      //   .then((res) => {
      //     if (res.data.code == 0) {
      //       this.NutritionalData = res.data.data;
      //     }
      //   });
    },
    // 删除训练动作
    delActions(u_index, u_idx, u_type) {
      if (u_type) {
        this.weekAddData[this.span_index].templates[u_index].actions.splice(
          u_idx,
          1
        );
        if (
          !this.weekAddData[this.span_index].templates[u_index].actions.length
        ) {
          this.weekAddData[this.span_index].templates.splice(u_index, 1);
        }
      } else {
        this.weekEditData.templates[u_index].actions.splice(u_idx, 1);
        if (!this.weekEditData.templates[u_index].actions.length) {
          this.weekEditData.templates.splice(u_index, 1);
        }
      }
    },
    // 删除训练方案
    handle_del(u_index, u_type) {
      if (u_type) {
        this.weekAddData[this.span_index].templates.splice(
          u_index,
          1
        );
      }
    },
    // 计算选择时长
    timesChange(u_index, u_value, u_type) {
      if (u_type) {
        if (!u_value) {
          this.weekAddData[this.span_index].templates[u_index].timelen = "";
          return;
        }
        let start_time = u_value[0].split(":");
        let end_time = u_value[1].split(":");
        let start_min = Number(start_time[0] * 60) + Number(start_time[1]);
        let end_min = Number(end_time[0] * 60) + Number(end_time[1]);
        let over_time = end_min - start_min;
        this.weekAddData[this.span_index].templates[u_index].timelen =
          over_time;
        this.weekAddData[this.span_index].templates[u_index].start_time =
          u_value[0];
        this.weekAddData[this.span_index].templates[u_index].end_time =
          u_value[1];
      } else {
        if (!u_value) {
          this.weekEditData.templates[u_index].timelen = "";
          return;
        }
        let start_time = u_value[0].split(":");
        let end_time = u_value[1].split(":");
        let start_min = Number(start_time[0] * 60) + Number(start_time[1]);
        let end_min = Number(end_time[0] * 60) + Number(end_time[1]);
        let over_time = end_min - start_min;
        this.weekEditData.templates[u_index].timelen = over_time;
        this.weekEditData.templates[u_index].start_time = u_value[0];
        this.weekEditData.templates[u_index].end_time = u_value[1];
        let minlen = 0;
        this.weekEditData.templates.forEach((itm) => {
          minlen += Number(itm.timelen);
        });
        this.weekEditData.plan_timelen = minlen;
      }
    },
    // 选择模版获取动作
    templateChange(u_index, u_value, u_type) {
      if (u_type) {
        this.weekAddData[this.span_index].templates[u_index].actions = [];
        if (
          this.weekAddData[this.span_index].templates[u_index].template_uuid ==
          0
        ) {
          this.weekAddData[this.span_index].templates[u_index].actions.push({
            action_uuid: "",
            gnum: "",
            tnum: "",
            timelen: "",
            ggap: "",
            loads: "",
          });
        } else {
          this.$axios
            .post(
              "/p/template/read",
              this.$qs({
                uuid: u_value,
              })
            )
            .then((res) => {
              if (res.data.code == 0) {
                let actions = res.data.data.detail;
                let actionsArr = [];
                if (actions.length) {
                  actions.forEach((item, index) => {
                    item.action.forEach((itm) => {
                      this.weekAddData[this.span_index].templates[
                        u_index
                      ].actions.push(itm);
                      this.actionsList = this.$dataBack(
                        this.actionsList,
                        { name: itm.action_name, uuid: itm.action_uuid },
                        "uuid",
                        "name"
                      );
                    });
                  });
                }
              }
            });
        }
        this.templateAll.forEach((item, index) => {
          if (item.uuid == u_value) {
            this.$set(
              this.weekAddData[this.span_index].templates[u_index],
              "template_name",
              item.name
            );
          }
        });
      } else {
        this.weekEditData.templates[u_index].actions = [];
        if (this.weekEditData.templates[u_index].template_uuid == 0) {
          this.weekEditData.templates[u_index].actions.push({
            action_uuid: "",
            gnum: "",
            tnum: "",
            timelen: "",
            ggap: "",
            loads: "",
          });
        } else {
          this.$axios
            .post(
              "/p/template/read",
              this.$qs({
                uuid: u_value,
              })
            )
            .then((res) => {
              if (res.data.code == 0) {
                let actions = res.data.data.detail;
                let actionsArr = [];
                if (actions.length) {
                  actions.forEach((item, index) => {
                    item.action.forEach((itm) => {
                      this.weekEditData.templates[u_index].actions.push(itm);
                      this.actionsList = this.$dataBack(
                        this.actionsList,
                        { name: itm.action_name, uuid: itm.action_uuid },
                        "uuid",
                        "name"
                      );
                    });
                  });
                }
              }
            });
        }
        this.templateAll.forEach((item, index) => {
          if (item.uuid == u_value) {
            this.$set(
              this.weekEditData.templates[u_index],
              "template_name",
              item.name
            );
          }
        });
      }
    },
    // 周训练计划 -- 增加动作
    addActions(u_index, u_type) {
      if (u_type) {
        this.weekAddData[this.span_index].templates[u_index].actions.push({
          action_uuid: "",
          gnum: "",
          tnum: "",
          timelen: "",
          ggap: "",
          loads: "",
        });
      } else {
        this.weekEditData.templates[u_index].actions.push({
          action_uuid: "",
          gnum: "",
          tnum: "",
          timelen: "",
          ggap: "",
          loads: "",
        });
      }
    },
    // 周训练计划 -- 增加训练模版
    addTemplate(u_type) {
      if (u_type) {
        this.weekAddData[this.span_index].templates.push({
          template_uuid: "",
          template_name: "",
          times: "",
          start_time: "",
          end_time: "",
          timelen: "",
          actions: [],
        });
      } else {
        this.weekEditData.templates.push({
          template_uuid: "",
          template_name: "",
          times: "",
          start_time: "",
          end_time: "",
          timelen: "",
          actions: [],
        });
      }
    },
    // 获取周训练计划列表
    getList() {
      this.contentLoading = true;
      this.$axios
        .post(
          "/p/train/lists",
          this.$qs({
            organize_uuid: this.deparmentId,
            type: 1,
            start_date: this.dateList[0],
            end_date: this.dateList[6],
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.interDataList = res.data.data[0];
          }
          this.contentLoading = false;
        });
    },
    deparmentChange() {
      this.dateList = this.getWeekDays(this.date);
      this.getList();
      this.getTrain();
      this.getParticipant()
    },
    // 获取周训练概述
    getTrain() {
      this.$axios
        .post(
          "/p/train/getTrain",
          this.$qs({
            type: 1,
            organize_uuid: this.deparmentId,
            start_date: this.dateList[0],
            end_date: this.dateList[6],
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.trainInput = res.data.data.summary;
            this.fileListUuid = res.data.data.uuid;
            this.fileNum = res.data.data.annex_quantity;
          }
        });
    },
    // 保存周训练概述
    trainBlur() {
      this.$axios
        .post(
          "/p/train/saveSummary",
          this.$qs({
            type: 1,
            organize_uuid: this.deparmentId,
            summary: this.trainInput,
            start_date: this.dateList[0],
            end_date: this.dateList[6],
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    // 保存实际时长 -- RPE
    realValueChange(u_idx, u_uuid, u_value, u_plan_rpe) {
      if(u_value < 0) {
        this.interDataList.details[u_idx].real_timelen = ''
        return  this.$message({
                  message: '您输入的结果值可能太小哦！',
                  type: 'error'
                })
      } else if (u_value > 1440) {
        this.interDataList.details[u_idx].real_timelen = ''
        return  this.$message({
                  message: '您输入的结果值可能太大哦！',
                  type: 'error'
                })
      }

      this.$axios
        .post(
          "/p/train/saveParam",
          this.$qs({
            type: 1,
            organize_uuid: u_uuid,
            train_date: u_idx,
            real_timelen: u_value,
            plan_rpe: u_plan_rpe,
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    getDeparment() {
      return new Promise((resolve, reject) => {
        this.$axios.post("/p/staff/tree").then((res) => {
          if (res.data.code == 0) {
            this.departmentOptions = res.data.data.department;
            this.deparmentId = getUuid(this.departmentOptions)
            function getUuid (data) {
              if(data[0].children) {
                return getUuid(data[0].children)
              } else {
                return data[0].uuid
              }
            }
         
            resolve();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
            reject();
          }
        });
      });
    },
    memberChange() {
      let index = this.checkMember.indexOf("0");
      if (index !== -1) {
        let data = [];
        this.staffByList.forEach((itm) => {
          data.push(itm.uuid);
        });
        this.checkMember = data;
        this.checkMember.splice(0, 1);
      }
    },
    // 获取运动员
    getStaffList() {
      this.checkMember = [];
      if (!this.addDeparmentId) {
        this.staffByList = [];
        return;
      }
      this.$axios
        .post(
          "/p/Staff/getStaffByStation",
          this.$qs({
            department_uuid: this.addDeparmentId,
            type: "staff",
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.staffByList = res.data.data;
            this.staffByList.unshift({
              name: "全部",
              uuid: "0",
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    // dateChange(value) {
    //   this.dateList = this.getWeekDays(value);
    //   this.getList();
    //   setTimeout(() => {
    //     this.getTrain();
    //   }, 0);
    // },
    // 周训练计划 -- 新增
    weekPlanAdd() {
      this.contentLoading = true
      this.addWeekDays(new Date());
      this.weekPlanDialog = true;
      this.referToButton = true

      this.addDeparmentId = this.deparmentId
      this.getStaffList()
      // this.getTrainTeam.attend_staffs.forEach(item => {
      //   this.checkMember.push(item.uuid)
      // })
      setTimeout(() => {
        this.contentLoading = false
      }, 500)

      this.$axios.post("/p/action/all").then((res) => {
        if (res.data.code == 0) {
          this.actionsList = res.data.data;
          this.$forceUpdate();
        }
      });

      this.$axios.post("/p/template/all").then((res) => {
        if (res.data.code == 0) {
          this.templateAll = res.data.data;
        }
      });
    },
    // 周训练计划 -- 取消
    weekPlanCancel() {
      this.weekPlanDialog = false;
      this.addDeparmentId = "";
      this.referToDate = ''
      this.checkMember = [];
      this.staffByList = [];
      this.addDate = new Date();
      this.addWeekDays(new Date());
      this.span_index = 0;
      this.weekAddData = [];
      for (let i = 0; i < 7; i++) {
        this.weekAddData.push({
          train_date: "",
          target: "",
          plan_timelen: "",
          real_timelen: "",
          rpe: "",
          templates: [],
        });
      }
    },
    // 周训练计划 -- 保存
    weekPlanSave() {
      this.$axios
        .post(
          "/p/train/saveTrain",
          this.$qs({
            type: 1,
            organize_uuid: this.addDeparmentId,
            staff_uuid_str: this.checkMember.toString(","),
            is_edit: this.referToButton ? 0 : 1,
            days: JSON.stringify(this.weekAddData),
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.weekPlanCancel();
            this.getList();
            this.getParticipant()
            this.referToDate = ''
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    // 个人编辑 -- 保存
    editPlanSave() {
      this.$axios
        .post(
          "/p/train/saveTrain",
          this.$qs({
            staff_uuid_str: this.memberInfo.staff_uuid,
            is_edit: 1,
            days: JSON.stringify([this.weekEditData]),
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.editPlanCancel();
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
      this.editkPlanDialog = false;
    },
    // 个人编辑 -- 取消
    editPlanCancel() {
      this.editkPlanDialog = false;
      this.weekEditData = [];
      this.memberInfo = ""; // 个人信息
      this.memberDate = ""; // 日期
      this.memberWeek = ""; // 星期几
      this.NutritionalData = {};
      this.tab_index = true;
      this.weekEditData.push({
        train_date: "",
        target: "",
        plan_timelen: "",
        real_timelen: "",
        rpe: "",
        templates: [],
      });
    },
    addWeekIndex(index) {
      this.span_index = index;
    },
    addWeekDays(date) {
      this.addDateList = this.getWeekDays(date);
      this.addDateList.map((item, index) => {
        this.weekAddData[index].train_date = item;
      });
    },
    // 获取一周日期
    getWeekDays(input_day) {
      let oneDayTime = 1000 * 60 * 60 * 24;
      let today = new Date(input_day);
      // 若那一天是周末时，则强制赋值为7
      let todayDay = today.getDay() || 7;
      let startDate = new Date(today.getTime() - oneDayTime * (todayDay - 1));
      let dateList = new Array();
      for (let i = 0; i < 7; i++) {
        let temp = new Date(startDate.getTime() + i * oneDayTime);
        let year = temp.getFullYear();
        let month = temp.getMonth() + 1;
        let day = temp.getDate();
        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;
        dateList[i] = year + "-" + month + "-" + day;
      }
      return dateList;
    },
    // 个人新增  切换tab
    tabClick(type) {
      this.tab_index = type;
    },
    // 跳转个人页面
    personageClick(uuid) {
      this.$router.push({
        path: "/trainingPlan/weekly/personagePage",
        query: {
          uuid: uuid,
          start_time: this.dateList[0],
          end_time: this.dateList[6],
        },
      });
    },
    // 格式化日期格式为 xxxx-xx-xx
    getFullDate(targetDate) {
      var D, y, m, d;
      if (targetDate) {
        D = new Date(targetDate);
        y = D.getFullYear();
        m = D.getMonth() + 1;
        d = D.getDate();
      } else {
        y = fullYear;
        m = month;
        d = date;
      }
      m = m > 9 ? m : "0" + m;
      d = d > 9 ? d : "0" + d;
      return y + "-" + m + "-" + d;
    },
    onInputValue(index, idx, name, type) {
      if (type) {
        let value =
          this.weekAddData[this.span_index].templates[index].actions[idx][name];
        if (!value.match(/^[1-9]\d{0,3}$/)) {
          this.weekAddData[this.span_index].templates[index].actions[idx][
            name
          ] = "";
        }
      } else {
        let value = this.weekEditData.templates[index].actions[idx][name];
        if (!value.match(/^[1-9]\d{0,3}$/)) {
          this.weekEditData.templates[index].actions[idx][name] = "";
        }
      }
    },

    // ***************** 附件上传
    // 附件弹框
    uploadBtn(rows) {
      if(this.$store.getters.permissionsStr('附件')) {
        return this.$message({
          message: '暂无权限',
          type: 'warning'
        })
      }
      this.contentLoading = true;
      this.fileDialogVisible = true;
      // this.fileNum = rows.annex_quantity
      // this.is_uuid = rows.uuid

      this.$axios
        .post(
          "/p/annex/lists",
          this.$qs({
            uuid: this.fileListUuid,
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.files = res.data.data.rows;
            res.data.data.rows.forEach((itm, index) => {
              // item.url = 'http://' + item.file_path
              let type = this.nameMatch(itm);
              if (type) {
                this.files[index].url = type;
              } else {
                this.files[index].url = "http://" + this.files[index].file_path;
              }
            });
            this.newFileList = res.data.data.rows;
          }
          setTimeout(() => {
            this.contentLoading = false;
          }, 200);
        });
    },
    // 附件上传拦截
    beforeUpload(file) {
      const size = file.size / 1024 / 1024;
      if (size > 20) {
        this.$message({
          title: "warning",
          message: "文件大小超过20M",
        });
        return false;
      } else {
        return true;
      }
    },
    // 附件下载
    upload_down() {
      if (!this.checkList.length) {
        this.$message({
          type: "error",
          message: "请选择要下载的附件",
        });
        return false;
      }
      let arr = [];
      this.checkList.forEach((item, index) => {
        this.files.forEach((itm) => {
          if (item == itm.name) {
            arr.push(itm.file_path);
          }
        });
      });

      let path = "";
      arr.forEach((i, k) => {
        if (k == arr.length - 1) {
          path += `file_array[${k}]=${i}`;
        } else {
          path += `file_array[${k}]=${i}&`;
        }
      });

      let url = this.$store.state.img_url + "/p/download/zip?" + path;
      window.open(url);
    },
    // 附件删除
    option_del() {
      let arr = "";
      this.checkList.forEach((item, index) => {
        this.files.forEach((itm, ix) => {
          if (item == itm.name) {
            if (ix == 0) {
              arr = itm.file_path;
            } else {
              arr += "," + itm.file_path;
            }
          }
        });
      });

      this.$axios
        .post(
          `/p/annex/delete`,
          this.$qs({
            file_path: arr,
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.fileNum = this.fileNum - this.checkList.length;
            let checkLength = this.checkList.length;
            while (checkLength--) {
              var length = this.files.length;
              while (length--) {
                if (this.files[length].name == this.checkList[checkLength]) {
                  this.files.splice(length, 1);
                  this.checkList.splice(checkLength, 1);
                }
              }
            }
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    //成功的时候
    success(res, file, fileList) {
      if (res.code == 0) {
        this.fileNum++;
        this.files.push(file.response.data);
        // this.$message({
        //   type: "success",
        //   message: res.message
        // });
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
        for (let i in fileList) {
          if (fileList[i].name == file.name) {
            fileList.splice(i, 1);
          }
        }
      }
      let type = this.nameMatch(file);
      if (type) {
        this.files.forEach((item) => {
          if (item.name == file.name) {
            item.url = type;
          }
        });
      } else {
        this.files.forEach((item) => {
          if (item.name == file.name) {
            item.url = "http://" + item.file_path;
          }
        });
      }
    },
    // 附件弹框关闭
    fileClose() {
      this.fileDialogVisible = false;
      // this.create(this.currentPage)
    },
    nameMatch(name) {
      let suffix = ""; // 后缀
      let result = ""; // 类型结果
      let imgUrl = "";

      var fileArr = name.name.split(".");
      suffix = fileArr[fileArr.length - 1];

      if (!suffix) {
        result = false;
        return result;
      }

      let typeList = [
        "png",
        "jpg",
        "jpeg",
        "bmp",
        "gif",
        "webp",
        "psd",
        "svg",
        "tiff",
      ];
      result = typeList.some((item) => {
        return item == suffix;
      });
      if (result) {
        return false;
      }

      switch (suffix) {
        case "docx":
          imgUrl = this.wordUrl;
          break;
        case "rtf":
          imgUrl = this.wordUrl;
          break;
        case "xls":
          imgUrl = this.xlsxUrl;
          break;
        case "xlsx":
          imgUrl = this.xlsxUrl;
          break;
        case "ppt":
          imgUrl = this.pptsUrl;
          break;
        case "pptx":
          imgUrl = this.pptsUrl;
          break;
        case "pdf":
          imgUrl = this.pdfUrl;
          break;
        default:
          imgUrl = this.morenUrl;
          break;
      }
      return imgUrl;
    },
    fileTofile(item) {
      this.imgDialog = true;
      this.dialogImageUrl = item.url;
    },
    // ********************

    getColorClass(value) {
      if (value % 5 == 0) {
        return "one_color";
      } else if (value % 5 == 1) {
        return "two_color";
      } else if (value % 5 == 2) {
        return "three_color";
      } else if (value % 5 == 3) {
        return "four_color";
      } else if (value % 5 == 4) {
        return "five_color";
      }
    },

    // 获取历史模版
    dateChange() {
      this.$confirm("选择参照后，历史数据全部应用到本次训练计划中，且覆盖本次原有记录！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
        cancelButtonClass: "",
        center: true
      }).then(res => {
        this.span_index = 0
        let dateList = this.getWeekDays(this.referToDate)
        this.$axios.post("/p/train/readWeek", this.$qs({
          type: 1,
          organize_uuid: this.deparmentId,
          start_date: dateList[0],
          end_date: dateList[6]
        })).then(res => {
          if(res.data.code == 0) {
            // let date = this.weekAddData[this.span_index].train_date
            // this.weekAddData[this.span_index] = res.data.data.train
            // this.weekAddData[this.span_index].train_date = date
            // this.weekAddData[this.span_index].templates.forEach(item => {
            //   this.$set(item, 'times', [item.start_time, item.end_time])
            //   item.actions.forEach(im => {
            //     this.actionsList = this.$dataBack(this.actionsList, {name: im.action_name, uuid: im.action_uuid}, 'uuid', 'name')
            //   })
            // })
            // this.referToDate = ''
            // this.$forceUpdate()
            if(res.data.data.train.length) {
              res.data.data.train.forEach((item, index) => {
                item.train_date = this.addDateList[index]
                if(item.templates) {
                  item.templates.forEach(itm => {
                    itm.train_date = this.addDateList[index]
                    this.$set(itm, 'times', [itm.start_time, itm.end_time])
                    itm.actions.forEach(im => { 
                      this.actionsList = this.$dataBack(this.actionsList, {name: im.action_name, uuid: im.action_uuid}, 'uuid', 'name')
                    })
                  })
                } else {
                  item.templates = []
                }
              })
            } else {
              for(var i = 0; i < 7; i++) {
                res.data.data.train.push({
                  train_date: this.addDateList[i],
                  target: "",
                  plan_timelen: "",
                  real_timelen: "",
                  rpe: "",
                  templates: []
                })
              }
            }
            
            this.weekAddData = res.data.data.train
          }
        })
      }).catch(() => {
        this.referToDate = ''
        this.$message({
          type: 'info',
          message: '已取消参照'
        });          
      });
      
    },
  },
};
</script>

<style lang="scss" scoped>
.teamPage {
  padding-top: 10px;
  .child-top {
    border-radius: 5px;
    .input-from {
      float: left;
      min-width: 1150px;
      margin-bottom: 0;

      .picker_date {
        float: left;
        margin-top: 20px;
        width: 240px;
      }

      & > .el-select {
        width: 150px;
        float: left;
        margin-top: 20px;
      }

      .el-select,
      .el-input {
        margin-top: 20px;
      }

      .el-input.el-input--suffix.left-child-input {
        float: left;
        width: 200px;
      }

      .el-cascader {
        margin-top: 18px;
        min-width: 150px;
      }

      .searchBtn {
        margin-top: 20px;
      }
      .el-date-editor {
        border-bottom: none;
      }
    }

    .operation-button {
      // float: left;
      margin-bottom: 10px;
    }
  }
  .teamPage_content {
    width: 100%;
    height: auto;
    margin-top: 10px;
    background: #fff;
    padding: 10px 30px;
    box-sizing: border-box;
    .teamPage_content_sum {
      display: flex;
      height: 90px;
      .sum_name {
        display: block;
        width: 120px;
        line-height: 90px;
        text-align: center;
        background: #ececec;
        border: 1px solid #e2e2e2;
        border-right: none;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
      .sum_input {
        /deep/ .el-input__inner{
          height: 100%;
          border-radius: 0;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          &:focus {
            border-color: #0055e9;
          }
        }
      }
      
      .sum_input {
        /deep/ .el-textarea__inner {
          height: 100%;
          border-radius: 0;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          &:focus {
            border-color: #0055e9;
          }
        }
      }
      ._file {
        width: 200px;
        margin-left: 20px;
        text-align: center;
        line-height: 90px;
        border: 1px solid #d7dbe0;
        font-size: 16px;
        border-radius: 2px;
        i {
          color: #0055e9;
          font-size: 22px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .teamPage_content_people {
      display: flex;
      margin-top: 10px;
      .sum_people {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        padding: 25px 0;
        box-sizing: border-box;
        text-align: center;
        background: #ececec;
        border: 1px solid #e2e2e2;
        border-right: none;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
      .roll_people{
        flex: 1;
        border-radius: 2px;
        border: 1px solid #E2E2E2;
        padding: 10px;
        padding-bottom: 0;
        position: relative;
        height: 95px;
        overflow: hidden;
        li{
          display: inline-block;
          width: 80px;
          height: 35px;
          text-align: center;
          line-height: 35px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #666666;
          border-radius: 21px;
          border: 1px solid #CCCCCC;
          margin-left: 10px;
          margin-bottom: 10px;
          overflow: hidden;
          text-overflow:ellipsis; 
          white-space: nowrap;
          &:hover{
            cursor: pointer;
            color: #0055E9;
            border-color: #0055E9;
          }
        }
        .downBtn{
          position: absolute;
          right: 10px;
          bottom: 10px;
          font-size: 30px;
          &:hover{
            cursor: pointer;
          }
        }
      }
      .roll_active{
        height: auto;
      }
      .other_people {
        width: 180px;
        line-height: 90px;
        text-align: center;
        border-radius: 2px;
        border: 1px solid #E2E2E2;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
          color: #276FEC;
          cursor: pointer;
          .detail_people{
            display: flex;
          }
          .trigon-icon{
            display: block;
          }
        }
        .trigon-icon{
          color: #F2F6FE;
          position: absolute;
          bottom: -10px;
          left: 80px;
          font-size: 30px;
          display: none;
        }
        .detail_people{
          width: 180px;
          max-height: 300px;
          overflow-y:auto;

          position: absolute;
          top: 90px;
          left: 0px;
          background: #F2F6FE;
          // padding-top: 20px;
          // padding-bottom: 10px;
          box-sizing: border-box;
          // display: flex;
          flex-wrap: wrap;
          border-radius: 5px;
          display: none;
          z-index: 9  ;
          li{
            width: 70px;
            height: 35px;
            margin-top: 10px;
            text-align: center;
            line-height: 35px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #666666;
            border-radius: 21px;
            border: 1px solid #CCCCCC;
            margin-bottom: 10px;
            margin-left: 10px;
            &:hover{
              cursor: pointer;
              color: #0055E9;
              border-color: #0055E9;
             
            }
          }
        }
      }
    }
    .date_head {
      width: 100%;
      background: #eeeeee;
      display: flex;
      border: 1px solid #c9c9c9;
      height: 50px;
      line-height: 50px;
      margin-top: 10px;
      span {
        display: inline-block;
        flex: 1;
        text-align: center;
        border-right: 1px solid #c9c9c9;
        font-size: 14px;
        color: #666;
        &:last-child {
          border-right: none;
        }
      }
      .date_active {
        color: #0055e9;
      }
    }
    .member_content {
      // margin-top: 10px;
      .member_content_top {
        height: 70px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        background: rgba(0, 85, 233, 0.05);
        &:hover {
          cursor: pointer;
        }
        /deep/ .el-avatar {
          width: 50px;
          height: 50px;
          line-height: 50px;
        }

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
        span {
          font-size: 18px;
          color: #333;
          margin-left: 30px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          display: inline-block;
          max-width: 300px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          &:first-child {
            margin-left: 0px;
          }
          &:nth-child(2) {
            margin-left: 20px;
          }
        }
      }
      .member_content_ul {
        display: flex;
        border: 1px solid #e2e2e2;
        height: 300px;
        li {
          flex: 1;
          box-sizing: border-box;
          position: relative;
          border-right: 1px solid #e2e2e2;
          overflow: hidden;
          &:last-child {
            border-right: none;
          }

          .li_top_con {
            height: 55%;
            padding: 20px 10px 15px 20px;
            box-sizing: border-box;
            overflow-y: auto;
            &:hover {
              cursor: pointer;
            }
            p {
              margin-bottom: 15px;
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              i {
                width: 10px;
                height: 10px;
                display: inline-block;
                border-radius: 50%;
                margin-right: 10px;
                background: #cfc;
              }
              &:first-child{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: pre-wrap;
                margin-bottom: 10px;
              }
              .one_color {
                background: #45a5ff;
              }
              .two_color {
                background: #ff706f;
              }
              .three_color {
                background: #4ecb73;
              }
              .four_color {
                background: #987ae0;
              }
              .five_color {
                background: #f8c22d;
              }
              strong {
                width: 80px;
                display: inline-block;
                margin-right: 10px;
                font-weight: 400;
              }
              span {
                // width: 100px;
                // display: inline-block;
              }
            }
          }
          .li_bot_con {
            height: 45%;
            background: #f7f7f7;
            padding-left: 30px;
            padding-top: 25px;
            box-sizing: border-box;
            p {
              color: #666666;
              font-size: 14px;
              margin-bottom: 10px;
              &:last-child {
                margin-bottom: 0;
              }
              span {
                display: inline-block;
                width: 90px;
              }
              .el-input {
                width: 50px;
              }
              /deep/ .el-input .el-input__inner {
                height: 25px;
								line-height: 25px;
                border-radius: 0;
                border-right-width: 0;
                border: none;
                border-bottom: 2px solid #ccc;
                background: #f7f7f7;
                padding: 0 5px;
              }
              /deep/ .el-input .el-input__inner:focus {
                border-color: #0055e9;
              }
              
              /deep/ .el-input__suffix {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.weekDialogContent {
  padding: 0 30px;
  .dialogContent_top {
    display: flex;
    strong {
      color: #f00;
      margin-right: 5px;
      font-size: 16px;
    }
    span {
      margin-right: 8px;
    }
    .top_left {
      width: 30%;
      margin-right: 20px;
    }
    .top_right {
      flex: 1;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      .el-select {
        flex: 1;
      }
    }
  }
  .dialogContent_date {
    display: flex;
    margin-top: 20px;
    .el-date-editor {
      width: 27.5%;
      margin-right: 50px;
    }
    .date-right {
      flex: 1;
      display: flex;
      position: relative;
      span {
        flex: 1;
        line-height: 40px;
        background: #ececec;
        margin-right: 15px;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .refer_to{
        background: rgba(0, 85, 233, 0.2);
        border-radius: 2px;
        border: 1px solid #0055E9;
        color: #0055E9;
        font-family: MicrosoftYaHei;
      }
      .span_active {
        background: #0055e9;
        color: #fff;
      }
      .el-date-editor{
        width: 10px;
        position: absolute;
        bottom: 10px;
        right: 50px;
        z-index: -1;
        /deep/ .el-input__inner{
          background: rgba(0, 85, 233, 0); 
          border: none;
          &:focus{
            border: none;
          }
        }
      }
    }
  }
  .dialogContent_title {
    margin-top: 20px;
    height: 55px;
    display: flex;
    span {
      width: 121px;
      line-height: 55px;
      text-align: center;
      display: inline-block;
      background: #ececec;
      border: 1px solid #e2e2e2;
      border-right: none;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      font-size: 14px;
      color: #000;
    }
    /deep/ .el-input {
      .el-input__inner {
        height: 55px;
        border: 1px solid #e2e2e2;
        border-radius: 0;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
      }
    }
  }
  .dialogContent_tableTitle {
    display: flex;
    border-left: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    span {
      height: 50px;
      display: inline-block;
      line-height: 50px;
      text-align: center;
      border-right: 1px solid #e3e3e3;
      color: #999999;
      font-size: 14px;
      &:first-child {
        width: 70px;
      }
      &:nth-child(2) {
        flex: 1;
        text-align: center;
      }
      &:last-child{
        width: 70px;
      }
    }
  }
  .dialogContent_table {
    max-height: 350px;
    overflow-y: auto;
  }
  .dialogContent_tableContent {
    display: flex;
    border: 1px solid #e3e3e3;
    border-top: none;
    ._tableContent_index {
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
    ._tableContent_right {
      flex: 1;
      ._table_right_top {
        background: #f7f7f7;
        border-bottom: 1px solid #e3e3e3;
        border-left: 1px solid #e3e3e3;
        .el-date-editor {
          margin: 15px 20px;
          border-radius: 0;
          border-right-width: 0;
          border: none;
          border-bottom: 2px solid #ccc;
          background: #f7f7f7;
          /deep/ .el-range-input {
            background: #f7f7f7;
          }
          &:focus {
            border-color: #0055e9;
          }
        }
        .el-input {
          width: 70px;
          background: #f7f7f7;
        }
        /deep/ .el-input .el-input__inner {
          background: #f7f7f7;
        }
        .new_btn {
          float: right;
          background: #1a9f16;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #fff;
          font-size: 15px;
          padding-left: 15px;
          padding-right: 15px;
          margin-top: 15px;
          margin-right: 20px;
          &:hover {
            background: #31b82d;
          }
          .add_icon {
            margin-right: 10px;
            font-weight: 600;
            font-size: 17px;
          }
        }
      }
      ._table_right_bot {
        display: flex;
        align-items: center;
        border-left: 1px solid #e3e3e3;
        ._right_bot_lf {
          width: 150px;
          padding: 0px 10px;
          .el-select {
            margin-bottom: 20px;
          }
        }
        ._right_bot_rg {
          flex: 1;
          overflow: hidden;
          padding: 20px 15px;
          padding-bottom: 10px;
          border-left: 1px solid #e3e3e3;
          li {
            margin-bottom: 20px;
          }
          .li_name {
            margin-left: 20px;
          }
          .el-input {
            // width: 80px;
            width: 60px;
            /deep/ .el-input__suffix-inner {
              color: #424242;
            }
          }
          .el-select {
            // width: 120px;
            width: 100px;
            margin-left: 10px;
            margin-right: 10px;
          }
          .del_icon {
            font-size: 22px;
            margin-left: 20px;
            color: #ed4a68;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    ._tableContent_handle{
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #E3E3E3;
      font-size: 15px;
      span{
        &:hover{
          color: #ed4a68;
          cursor: pointer;
        }
      }
    }
  }
  .new_btn_part {
    margin-top: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #0055e9;
    font-size: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border-color: #0055e9;
    &:hover {
      background: #fff;
    }
    .add_icon {
      margin-right: 10px;
      font-weight: 600;
      font-size: 17px;
    }
  }
}
.editDialog {
  /deep/ .el-dialog {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
      padding: 0 0 30px 0;
      .member_content_edit {
        height: 60px;
        display: flex;
        align-items: center;
        padding-left: 30px;
        background: rgba(0, 85, 233, 0.05);
        position: relative;
        color: #000;
        img {
          width: 45px;
          height: 45px;
          border-radius: 50%;
        }
        .el-avatar {
          width: 45px;
          height: 45px;
          line-height: 45px;
        }
        span {
          font-size: 16px;
          color: #333;
          margin-left: 30px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          display: inline-block;
          max-width: 300px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          &:first-child {
            margin-left: 0px;
          }
          &:nth-child(2) {
            margin-left: 20px;
          }
        }
        p {
          font-size: 17px;
          color: #666666;
          float: right;
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }
      .weekDialogContent {
        padding: 0px 50px;
        .dialogContent_title {
          margin-top: 10px;
        }
        .edit_content_tab {
          margin: 10px 0;
          span {
            display: inline-block;
            height: 48px;
            width: 110px;
            line-height: 48px;
            text-align: center;
            font-size: 15px;
            background: #ececec;
            color: #000;
            &:hover {
              cursor: pointer;
            }
          }
          .tab_active {
            background: #0055e9;
            color: #fff;
          }
        }
        .dialogContent_train {
          max-height: 400px;
          overflow-y: auto;
          padding-bottom: 20px;
          .dialogContent_tableMin {
            display: flex;
            border: 1px solid #e3e3e3;
            background: #f7f7f7;
            height: 55px;
            line-height: 55px;
            padding-left: 30px;
            font-size: 14px;
            .el-input {
              width: 70px;
              .el-input__inner {
                height: 35px;
                background: #f7f7f7;
              }
            }
            span {
              margin-left: 40px;
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
        .dialogContent_suggest {
          max-height: 400px;
          overflow-y: auto;
          padding-bottom: 20px;
          h2 {
            text-align: center;
            border: 1px solid #e3e3e3;
            border-bottom: none;
            height: 50px;
            line-height: 50px;
            color: #000;
            font-weight: 600;
          }
          .suggest_content {
            display: flex;
            border: 1px solid #e3e3e3;
            .suggest_content_left {
              width: 200px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-right: 1px solid #e3e3e3;
              font-size: 16px;
              font-weight: 400;
              color: #424242;
            }
            .suggest_content_right {
              flex: 1;
              .suggest_content_right_top {
                border-bottom: 1px solid #e3e3e3;
                display: flex;
                span {
                  text-align: center;
                  border-right: 1px solid #e3e3e3;
                  height: 50px;
                  line-height: 50px;
                  display: inline-block;
                  font-size: 16px;
                  font-weight: 500;
                  color: #424242;
                  padding: 0 10px;
                  &:last-child {
                    border-right: none;
                  }
                  &:nth-child(1) {
                    width: 120px;
                  }
                  &:nth-child(2) {
                    width: 120px;
                  }
                  &:nth-child(3) {
                    width: 120px;
                  }
                  &:nth-child(4) {
                    width: 120px;
                  }
                  &:nth-child(5) {
                    flex: 1;
                  }
                }
              }
              .suggest_content_right_content {
                li {
                  display: flex;
                  border-bottom: 1px solid #e3e3e3;
                  &:last-child {
                    border-bottom: none;
                  }
                  .right_content_li_name {
                    width: 120px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-right: 1px solid #e3e3e3;
                    font-size: 16px;
                    font-weight: 400;
                    padding: 0 10px;
                    color: #424242;
                  }
                  .right_content_li_content {
                    flex: 1;
                    .right_content_li_content_bot {
                      display: flex;
                      border-bottom: 1px solid #e3e3e3;
                      &:last-child {
                        border-bottom: none;
                      }
                      span {
                        border-right: 1px solid #e3e3e3;
                        padding: 14px 10px;
                        font-size: 16px;
                        font-weight: 400;
                        text-align: center;
                        color: #424242;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        &:last-child {
                          text-align: left;
                          border-right: none;
                        }
                        i {
                          width: 100%;
                          display: block;
                          font-style: normal;
                          line-height: 28px;
                        }
                        &:nth-child(1) {
                          width: 120px;
                        }
                        &:nth-child(2) {
                          width: 120px;
                        }
                        &:nth-child(3) {
                          width: 120px;
                        }
                        &:nth-child(4) {
                          flex: 1;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .suggest_botContent {
            display: flex;
            border: 1px solid #e3e3e3;
            border-top: none;
            .suggest_botContent_left {
              width: 200px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-right: 1px solid #e3e3e3;
              font-size: 16px;
              font-weight: 400;
              color: #424242;
            }
            .suggest_botContent_right {
              padding: 30px;
              line-height: 30px;
              overflow: hidden;
              font-size: 16px;
              font-weight: 400;
              color: #424242;
              flex: 1;
              p {
                font-size: 16px;
                font-weight: 400;
                color: #424242;
              }
            }
          }
        }
      }
    }
  }
}

// 附件弹框样式
.newDialog {
  /deep/ .el-dialog {
    .form_bot {
      // height: 330px;
      overflow-x: auto;
      margin-top: 20px;
      border-radius: 5px;
      border: 1px solid #ccc;
      min-height: 200px;
    }
  }
}
.inter_upload {
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  min-height: 200px;
}

.inter_opBtn {
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
  padding-right: 30px;
  .upload-demo {
    margin-right: 10px;
  }
  .el-button {
    width: 110px;
    height: 40px;
    border: none;
    &:nth-child(1) {
      background: #35c8cb;
    }
    &:nth-child(2) {
      background: #6a6ef8;
    }
    &:nth-child(3) {
      background: #ed4a68;
    }
  }
}

.el-form {
  margin-bottom: 30px;
  .formItem_cen {
    display: flex;
    .el-form-item {
      flex: 1;
      .el-date-editor {
        width: 100%;
        border-bottom: none;
        .el-input__inner {
          border-color: #0055e9 !important;
        }
      }
      .el-select {
        margin-top: 0;
        width: 100%;
      }
    }
  }
}
.inter_file {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  p {
    line-height: 16px;
    font-size: 17px;
    margin-bottom: 20px;
    span {
      display: inline-block;
      width: 5px;
      height: 16px;
      border-radius: 20px;
      background: #0055e9;
    }
  }
}

.upload_ul {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 20px 0;
  // padding-bottom: 10px;
  li {
    width: 150px;
    text-align: center;
    margin-left: 20px;
    position: relative;
    &:last-child {
      margin-right: 20px;
    }
    .el-checkbox {
      position: absolute;
      top: 0px;
      left: 0px;
      /deep/ .el-checkbox__inner {
        width: 20px;
        height: 20px;
        &::after {
          width: 6px;
          height: 13px;
          left: 5px;
        }
      }
      /deep/ .el-checkbox__label {
        display: none;
      }
    }
    &:hover {
      cursor: pointer;
    }
    &:first-child {
      margin-left: 20px;
    }
    .img_option {
      width: 150px;
      height: 150px;
      border-radius: 10px;
      border: 1px solid #ccc;
      overflow: hidden;
      position: relative;
      // border: 2px solid #fff;
      .img_dialog {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 85, 233, 0.11);
      }
    }
    .img_active {
      border: 1px solid #0055e9;
    }
    img {
      width: 100%;
      max-height: 150px;
    }
    p {
      margin: 10px;
      font-size: 16px;
      line-height: 22px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
}
</style>

<style lang="scss">
</style>